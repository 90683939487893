import { defineStore } from "pinia";
import { i18n } from "@/entrypoints/shared/i18n";
import { rtlLanguages } from "@/entrypoints/shared/i18n_constants";
import { ref, computed } from "vue";
import type { Locale, FrontendElection } from "@/types";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";

export const useSharedStore = defineStore("shared", () => {
  const votingModulesStore = useVotingModulesStore();

  // State
  const electionUrl = ref<string>(null);
  const election = ref<FrontendElection>(null);
  const locales = ref<Locale[]>([]);
  const currentLocale = ref<Locale>(i18n.global.locale as unknown as Locale);

  // Getters
  const firstAvailableLocale = computed(() => 
    locales.value.includes(i18n.global.locale.toString()) ? i18n.global.locale : locales.value[0]
  );

  // Actions
  const setElectionUrl = (payload: string) => {
    electionUrl.value = payload;
    votingModulesStore.setPostsUrl(electionUrl.value.replace("/observe", "") + "/posts");
  }

  const setElection = (payload: FrontendElection) => {
    election.value = payload;
  }

  const setLocales = (payload: Locale[]) => {
    locales.value = payload;
  }

  const setLocale = (payload: Locale) => {
    if (i18n.global.availableLocales.includes(payload)) {
      currentLocale.value = payload;
      i18n.global.locale = payload as any;
    }

    document.getElementsByTagName("html")[0].lang = payload;
    rtlLanguages.includes(payload)
      ? (document.getElementsByTagName("html")[0].dir = "rtl")
      : (document.getElementsByTagName("html")[0].dir = "ltr");
    document.title = election.value?.title[currentLocale.value];
  }

  return {
    electionUrl,
    setElectionUrl,
    election,
    setElection,
    locales,
    setLocales,
    currentLocale,
    firstAvailableLocale,
    setLocale,
  }
}, {
  persist: {
    storage: sessionStorage,
    paths: ["currentLocale"],
  },
});
