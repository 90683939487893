import apiClient from "@/entrypoints/frontend/apiClient";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import type { UserPost, PostType, HighlightPost } from "@/types";

export const useVotingModulesStore = defineStore("votingModules", () => {
  const votingSessionStore = useVotingSessionStore();

  // State
  const postsUrl = ref<string>(null);
  const posts = ref<UserPost[]>([]);
  const chatUser = reactive<UserPost>({
    id: null,
    type: null,
    name: null,
  });
  const activeHighlight = ref<HighlightPost>(null);
  const postToHighlight = ref<UserPost>(null);
  const postToAddToBallot = ref<UserPost>(null);

  // Actions
  const setPostsUrl = (payload: string) => postsUrl.value = payload;

  const setPosts = (payload: UserPost[]) => {
    const unreadIds = posts.value.filter((post: UserPost) =>
      post.unread === true).map((post: UserPost) => post.id);
    posts.value = payload;
    posts.value.forEach((post: UserPost) => unreadIds.includes(post.id) ? post.unread = true : null);
  }

  const setPostToHighlight = (payload: UserPost) => {
    if (payload === null) return postToHighlight.value = null
    postToHighlight.value = Object.assign({}, payload);
  }

  const setPostToAddToBallot = (payload: UserPost) => {
    if (payload === null) return postToAddToBallot.value = null;
    postToAddToBallot.value = Object.assign({}, payload);
  }

  const setActiveHighlight = (payload: HighlightPost) => activeHighlight.value = payload;

  const markAllPostsRead = (type: PostType) => {
    const filtered = posts.value.filter((p: UserPost) => p.type === type);
    filtered.forEach((p: UserPost) => p.unread = false);
  }

  const markPostRead = (id: number) => {
    const post = posts.value.find((post: UserPost) => post.id === id);
    if (post) post.unread = false;
  }

  const updatePosts = async () => {
    // app#status requires a voter session, observer#status does not, either can be accessed from this action
    const config = votingSessionStore ? {
      params: {
        signature: votingSessionStore.signature,
        voter_session_uuid: votingSessionStore.voterSessionUuid,
      }
    } : {};

    try {
      const response = await apiClient.get(`${postsUrl.value}`, config);
      const data = await response.data;
      setPosts(data.posts);
      chatUser.id = data.chatUser.id;
      chatUser.name = data.chatUser.name;
      chatUser.type = data.chatUser.type;
      console.log("posts updated");
    } catch (error) {
      console.error(error);
    }
  }

  const submitPost = async (post: UserPost) => {
    const config = votingSessionStore ? {
      params: {
        signature: votingSessionStore.signature,
        voter_session_uuid: votingSessionStore.voterSessionUuid,
      }
    } : {};

    return await apiClient.post(postsUrl.value, post, config);
  }

  const editPost = async (post: UserPost) => {
    const config = votingSessionStore ? {
      params: {
        signature: votingSessionStore.signature,
        voter_session_uuid: votingSessionStore.voterSessionUuid,
      }
    } : {};

    return await apiClient.put(`${postsUrl.value}/${post.id}`, post, config);
  }

  const deletePost = async (postId: number) => {
    const config = votingSessionStore ? {
      params: {
        signature: votingSessionStore.signature,
        voter_session_uuid: votingSessionStore.voterSessionUuid,
      }
    } : {};

    await apiClient.delete(`${postsUrl.value}/${postId}`, config);
  }

  const restorePost = async (postId: number) => {
    const config = votingSessionStore ? {
      params: {
        signature: votingSessionStore.signature,
        voter_session_uuid: votingSessionStore.voterSessionUuid,
      }
    } : {};

    await apiClient.post(`${postsUrl.value}/${postId}/restore`, {}, config);
  }

  return {
    posts,
    setPosts,
    updatePosts,
    activeHighlight,
    setActiveHighlight,
    postToAddToBallot,
    setPostToHighlight,
    setPostToAddToBallot,
    postToHighlight,
    chatUser,
    deletePost,
    restorePost,
    editPost,
    postsUrl,
    setPostsUrl,
    submitPost,
    markAllPostsRead,
    markPostRead,
  }
});
