import { getConsumerFor } from "./consumer";
import { UniqueChecker } from "@/lib/UniqueChecker";
import { Subscription } from "@rails/actioncable";
import { ref } from "vue";

class VoterChannelSubscription {
  public subscription: Subscription;
  private uniqueChecker: UniqueChecker<string>;
  private readonly voterSessionUuid: string;

  constructor(voterSessionUuid: string, cableToken: string | null, { received, connected = undefined, disconnected = undefined }) {
    this.uniqueChecker = new UniqueChecker<string>();
    this.voterSessionUuid = voterSessionUuid;
    this.subscription = getConsumerFor(cableToken).subscriptions.create(
      {
        channel: "VoterChannel",
        voter_session_uuid: this.voterSessionUuid,
      },
      {
        received: (data) => {
          if (this.uniqueChecker.wasSeen(data.uuid))
            return console.debug("DUPLICATE MESSAGE RECEIVED: ", data);
          received(data);
        },
        connected() {
          console.debug("VoterChannel connected")
        },
        disconnected() {
          console.debug("VoterChannel disconnected")
        },
      }
    );
  }

  unsubscribe() {
    this.subscription.perform(
      "log_out",
      { voter_session_uuid: this.voterSessionUuid }
    );
    this.subscription.unsubscribe();
  }
}

const voterChannelSubscription = ref(null);

export { VoterChannelSubscription, voterChannelSubscription };